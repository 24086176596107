import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import {
    FILTER_STATS_PRICE,
    FILTER_STATS_PRICE_TRADE,
    getFilterValues,
    getAppliedFilter,
} from '../sbSharedRefineMenuHelpers';
import get from 'lodash/get';

/* Components */
import { SbSharedRefineMenuRadioSelect } from '../../SbSharedRefineMenu/SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelect';

import styles from './SbSharedRefineMenuPriceRanges.scss';

const SbSharedRefinePriceRangesComponent = ({ itemSearch, user, currency }) => {
    const { displayUriRef = '', appliedFilters = [], filters = [] } = itemSearch;
    const isTradeUser = get(user, 'isVerifiedTrade');
    const filterName = isTradeUser ? FILTER_STATS_PRICE_TRADE : FILTER_STATS_PRICE;
    const appliedFilterValues = getFilterValues(appliedFilters, filterName);
    const appliedFilter = getAppliedFilter(appliedFilters, filterName);
    const priceFilterValues = getFilterValues(filters, filterName);
    const valuesToShow = priceFilterValues.filter(filter => filter.currency === currency) || [];
    const canBeDismissed = appliedFilter ? appliedFilter.canBeDismissed : true;

    return (
        <div className={styles.wrapper}>
            <SbSharedRefineMenuRadioSelect
                filterName={filterName}
                valuesToShow={valuesToShow}
                displayUriRef={displayUriRef}
                canBeDismissed={canBeDismissed}
                appliedFilterValues={appliedFilterValues}
                currency={currency}
                trackInputIndex
            />
        </div>
    );
};

SbSharedRefinePriceRangesComponent.propTypes = {
    user: PropTypes.object,
    itemSearch: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
};

export const SbSharedRefineMenuPriceRanges = createFragmentContainer(
    SbSharedRefinePriceRangesComponent,
    {
        user: graphql`
            fragment SbSharedRefineMenuPriceRanges_user on User {
                isVerifiedTrade
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuPriceRanges_itemSearch on ItemSearchQueryConnection {
                displayUriRef
                appliedFilters {
                    name
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                    }
                }
                filters {
                    name
                    values {
                        linkReference
                        linkable
                        displayName
                        urlLabel
                        currency
                    }
                }
            }
        `,
    }
);
