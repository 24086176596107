import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { DEFAULT_LOCATION_LABELS } from '../sbSharedRefineMenuConstants';
import { useSbSelector } from '../../../reducers/useSbSelector';
// components
import { SbSharedRefineMenuRadioSelectSearch } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectSearch';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import {
    type SbSharedRefineMenuFilterItemLocation_viewer$key,
    type SbSharedRefineMenuFilterItemLocation_viewer$data,
} from './__generated__/SbSharedRefineMenuFilterItemLocation_viewer.graphql';
import {
    type SbSharedRefineMenuFilterItemLocation_itemSearch$key,
    type SbSharedRefineMenuFilterItemLocation_itemSearch$data,
} from './__generated__/SbSharedRefineMenuFilterItemLocation_itemSearch.graphql';
import { type SbSharedRefineMenuFilters_filters$data } from './__generated__/SbSharedRefineMenuFilters_filters.graphql';

const viewerFragment = graphql`
    fragment SbSharedRefineMenuFilterItemLocation_viewer on Viewer {
        ...SbSharedRefineMenuRadioSelectSearch_viewer
        locations: itemFacetSearch(
            uriRef: $uriRef
            facetName: "location"
            facetLimit: 10
            zipCode: $userZipCode
            countryCode: $userCountryCode
        ) @include(if: $isClient) {
            edges {
                node {
                    count
                    displayName
                    urlLabel
                    linkReference
                }
            }
        }
    }
`;
const itemSearchFragment = graphql`
    fragment SbSharedRefineMenuFilterItemLocation_itemSearch on ItemSearchQueryConnection {
        ...SbSharedRefineMenuRadioSelectSearch_itemSearch
        appliedFilters {
            name
            values {
                count
                displayName
                urlLabel
                linkReference
            }
        }
    }
`;
type Value = NonNullable<
    NonNullable<
        NonNullable<
            NonNullable<
                SbSharedRefineMenuFilterItemLocation_itemSearch$data['appliedFilters']
            >[number]
        >['values']
    >[number]
>;

const getUserLocationValues = (
    viewer: SbSharedRefineMenuFilterItemLocation_viewer$data
): Value[] => {
    const locationsByZipCode = (viewer?.locations?.edges || [])
        .map(edge => edge?.node)
        .filter(filterFalsy);
    return locationsByZipCode.map(location => {
        const { displayName, urlLabel, count, linkReference } = location;
        return {
            displayName,
            urlLabel,
            count,
            linkReference,
        };
    });
};

type Props = {
    viewer: SbSharedRefineMenuFilterItemLocation_viewer$key;
    itemSearch: SbSharedRefineMenuFilterItemLocation_itemSearch$key;
    filterName: string;
    values: SbSharedRefineMenuFilters_filters$data[number]['values'];
};

export const SbSharedRefineMenuFilterItemLocation: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    filterName,
    values,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const { appliedFilters } = itemSearch;
    const facetFilterValues = (values || []).filter(filterFalsy);

    const userZipCode = useSbSelector(state => state.relayVariables.variables?.userZipCode);
    const userLocationValues =
        userZipCode && userZipCode !== '0' ? getUserLocationValues(viewer) : [];

    const appliedFilterValues = getFilterValues(appliedFilters, filterName).map(
        locationAppliedFilterValue => ({
            ...locationAppliedFilterValue,
            // Don't use item count on appliedFilters, count does not match the one shown in dropdown when new country is entered
            count:
                facetFilterValues.find(
                    facetFilterValue =>
                        locationAppliedFilterValue.urlLabel === facetFilterValue?.urlLabel
                )?.count || null,
        })
    );

    const validLocationLabels = new Set(
        [
            ...DEFAULT_LOCATION_LABELS,
            ...appliedFilterValues.map(filter => filter.urlLabel),
            ...userLocationValues.map(location => location?.urlLabel),
        ].filter(filterFalsy)
    );

    const orderedValues = [
        ...appliedFilterValues,
        ...facetFilterValues,
        ...userLocationValues,
    ].reduce<Value[]>((acc, curr) => {
        const { urlLabel } = curr;
        const isLocationAlreadyExists = acc?.find(item => item?.urlLabel === urlLabel);
        if (isLocationAlreadyExists) {
            return acc;
        } else if (urlLabel && validLocationLabels.has(urlLabel)) {
            acc.push(curr);
        }
        return acc;
    }, []);
    return (
        <SbSharedRefineMenuRadioSelectSearch
            viewer={viewer}
            itemSearch={itemSearch}
            filterName={filterName}
            values={orderedValues}
        />
    );
};
