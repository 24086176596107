import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'dibs-react-intl';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import { SbSharedRefineMenuMultiSelect } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelect';
import { SbSharedRefineMenuTypeahead } from '../SbSharedRefineMenuTypeahead/SbSharedRefineMenuTypeahead';
import { DEFAULT_LOCATION_LABELS } from '../sbSharedRefineMenuConstants';
import { useSbSelector } from '../../../reducers/useSbSelector';
import { useServerVarsContext } from '../../../global/ServerVarsContext/ServerVarsContext';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';

import { type SbSharedRefineMenuFilterShipsFrom_viewer$key } from './__generated__/SbSharedRefineMenuFilterShipsFrom_viewer.graphql';
import {
    type SbSharedRefineMenuFilterShipsFrom_itemSearch$key,
    type SbSharedRefineMenuFilterShipsFrom_itemSearch$data,
} from './__generated__/SbSharedRefineMenuFilterShipsFrom_itemSearch.graphql';
import { type SbSharedRefineMenuFilters_filters$data } from './__generated__/SbSharedRefineMenuFilters_filters.graphql';

const viewerFragment = graphql`
    fragment SbSharedRefineMenuFilterShipsFrom_viewer on Viewer {
        ...SbSharedRefineMenuTypeahead_viewer
        locations: itemFacetSearch(
            uriRef: $uriRef
            facetName: "location"
            facetLimit: 10
            zipCode: $userZipCode
            countryCode: $userCountryCode
        ) @include(if: $isClient) {
            edges {
                node {
                    count
                    displayName
                    urlLabel
                    linkReference
                }
            }
        }
    }
`;
const itemSearchFragment = graphql`
    fragment SbSharedRefineMenuFilterShipsFrom_itemSearch on ItemSearchQueryConnection {
        ...SbSharedRefineMenuRadioSelectSearch_itemSearch
        appliedFilters {
            name
            values {
                count
                displayName
                urlLabel
                linkReference
            }
        }
    }
`;

type Value = NonNullable<
    NonNullable<
        NonNullable<
            NonNullable<SbSharedRefineMenuFilterShipsFrom_itemSearch$data['appliedFilters']>[number]
        >['values']
    >[number]
>;

type Props = {
    viewer: SbSharedRefineMenuFilterShipsFrom_viewer$key;
    itemSearch: SbSharedRefineMenuFilterShipsFrom_itemSearch$key;
    filterName: string;
    values: SbSharedRefineMenuFilters_filters$data[number]['values'];
};

export const SbSharedRefineMenuFilterShipsFrom: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    filterName,
    values,
}) => {
    const intl = useIntl();
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const { isItemLocationFilterVariant } = useServerVarsContext();
    const { appliedFilters } = itemSearch;
    const facetFilterValues = (values || []).filter(filterFalsy);
    const regionsList = useSbSelector(state => state.relayVariables.variables?.regionsList);
    const userCountryCode = useSbSelector(state => state.relayVariables.variables?.userCountryCode);
    const userZipCode = useSbSelector(state => state.relayVariables.variables?.userZipCode);
    const userLocationValues =
        userZipCode && userZipCode !== '0'
            ? (viewer?.locations?.edges || []).map(edge => edge?.node).filter(filterFalsy)
            : [];

    const appliedFilterValues = getFilterValues(appliedFilters, filterName).map(appliedValue => {
        const count =
            facetFilterValues.find(
                facetFilterValue => appliedValue?.urlLabel === facetFilterValue?.urlLabel
            )?.count || null; // Don't use item count on appliedFilters, count does not match the one shown in dropdown when new country is entered
        return { ...appliedValue, count };
    });

    // User country or continent should be the first in the list
    const userCountry = values?.[0];
    const firstCountryIsUserCountry =
        (userCountry?.code && userCountry.code === userCountryCode) ||
        (userCountry?.code === 'USA' && userCountryCode === 'US') ||
        (userCountry?.code === 'AUSTRALIA' && userCountryCode === 'AU') ||
        (userCountry?.urlLabel && regionsList?.includes(userCountry.urlLabel));

    const validLocationLabels = new Set(
        [
            userCountry?.urlLabel,
            ...DEFAULT_LOCATION_LABELS,
            ...appliedFilterValues.map(filter => filter.urlLabel),
            ...userLocationValues.map(location => location?.urlLabel),
        ].filter(filterFalsy)
    );

    const orderedValues = [
        ...appliedFilterValues,
        ...facetFilterValues,
        ...userLocationValues,
    ].reduce<Value[]>((acc, curr) => {
        const { urlLabel } = curr;
        const isLocationAlreadyExists = acc?.find(item => item?.urlLabel === urlLabel);
        if (isLocationAlreadyExists) {
            return acc;
        } else if (urlLabel && validLocationLabels.has(urlLabel)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    const getIsDynamic = ({ urlLabel }: { urlLabel: string | null | undefined }): boolean => {
        return (
            (isItemLocationFilterVariant &&
                firstCountryIsUserCountry &&
                userCountry?.urlLabel &&
                userCountry.urlLabel === urlLabel) ||
            false
        );
    };

    const placeholder = intl.formatMessage({
        id: 'abf.shared.refine.menu.filter.ships.from',
        defaultMessage: 'Enter a city, country, or region',
    });
    return (
        <>
            <SbSharedRefineMenuMultiSelect
                filterName={filterName}
                appliedFilterValues={appliedFilterValues}
                valuesToShow={orderedValues}
                getIsDynamic={getIsDynamic}
            />
            <SbSharedRefineMenuTypeahead
                viewer={viewer}
                filterName={filterName}
                excludedDisplayNames={[]}
                handleToggling={() => {}}
                onResultClick={() => {}}
                placeholder={placeholder}
                hideControls
            />
        </>
    );
};
