import get from 'lodash/get';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';

// components
import { SbSharedRefineMenuCaratWeightRange } from './SbSharedRefineMenuCaratWeightRange';
import { SbSharedRefineMenuRadioSelectList } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectList';

// styles
import styles from './SbSharedRefineMenuCaratWeight.scss';

function SbSharedRefineMenuCaratWeightComponent(props) {
    const { filterName, filter, itemSearch, applyOnBlur } = props;
    const { appliedFilters } = itemSearch;

    const appliedFilterValues = getFilterValues(appliedFilters, filterName);

    // The preset multi-select carat buckets are all the other items in "values" array.
    const presetValues = get(filter, 'values').slice(1);

    return (
        <div className={styles.container}>
            <SbSharedRefineMenuCaratWeightRange
                filter={filter}
                applyOnBlur={applyOnBlur}
                values={appliedFilterValues}
            />
            <SbSharedRefineMenuRadioSelectList
                values={presetValues}
                filterName={filterName}
                itemSearch={itemSearch}
                notDismissable={false}
                collapsedItemCount={5}
            />
        </div>
    );
}

SbSharedRefineMenuCaratWeightComponent.propTypes = {
    filter: PropTypes.object,
    applyOnBlur: PropTypes.bool,
    itemSearch: PropTypes.shape({
        appliedFilters: PropTypes.array,
    }),
    values: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
};

export const SbSharedRefineMenuCaratWeight = createFragmentContainer(
    SbSharedRefineMenuCaratWeightComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuCaratWeight_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectList_itemSearch
                appliedFilters {
                    name
                    values {
                        urlLabel
                        displayName
                    }
                }
            }
        `,
    }
);
