import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';
import { SbSharedRefineMenuInputCheckbox } from '../SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';

type FilterValue = {
    readonly count: number | null;
    readonly displayName: string | null;
    readonly linkReference: string | null;
    readonly urlLabel: string | null;
    readonly isHidden?: boolean | null;
    readonly linkable?: boolean | null;
};

type Props = {
    appliedFilterValues: FilterValue[];
    filterName: string;
    valuesToShow: FilterValue[];
    getIsDynamic?: (filterValue: FilterValue) => boolean | undefined;
};

export const SbSharedRefineMenuMultiSelect: FC<Props> = ({
    appliedFilterValues,
    filterName,
    valuesToShow,
    getIsDynamic = () => false,
}) => {
    const dispatch = useDispatch();

    return (
        <>
            {valuesToShow.map((filterValue, index) => {
                const isChecked = appliedFilterValues.some(
                    appliedValue => appliedValue.urlLabel === filterValue.urlLabel
                );
                const isDynamic = getIsDynamic(filterValue);
                return (
                    <SbSharedRefineMenuInputCheckbox
                        key={`${filterValue.urlLabel}-${index}`}
                        value={filterValue.urlLabel || ''}
                        checked={isChecked}
                        linkReference={filterValue.linkReference}
                        linkable={filterValue.linkable}
                        onChange={(_checked, event) => {
                            dispatch(
                                updateUriRef({
                                    filterName,
                                    filterValue,
                                    isDeselected: isChecked,
                                    isDynamic,
                                    event,
                                    uriRef: undefined,
                                    ga: undefined,
                                })
                            );
                        }}
                        text={filterValue.displayName || ''}
                        count={filterValue.count}
                        isHidden={filterValue.isHidden}
                    />
                );
            })}
        </>
    );
};
