import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';
import { FormattedNumber, FormattedMessage } from 'dibs-react-intl';
import slugify from 'dibs-slugify/exports/slugify';
import { Link } from 'dibs-elements/exports/Link';

import styles from './SbSharedRefineMenuSearchResults.scss';

function SbSharedRefineMenuSearchResultsComponent(props) {
    const { searchString, onResultClick, isLoading, excludedDisplayNames, iconMap, id, ariaLive } =
        props;
    const results = get(props, 'itemFacetSearch.edges');
    const normalizedResults = (results || []).map(edge => edge.node);
    const resultsToShow = normalizedResults
        .slice(0, 5)
        .filter(result => !excludedDisplayNames.includes(result.displayName));

    let markup;

    if (resultsToShow.length) {
        markup = (
            <ul className={styles.resultList}>
                {resultsToShow.map(result => {
                    const IconComponent = iconMap?.[result.urlLabel];
                    return (
                        <li key={result.urlLabel} className={styles.listItem}>
                            <Link
                                buyerLinkType="linkNoDecoration"
                                className={styles.result}
                                onClick={e => onResultClick(result, e)}
                                dataTn={`search-result-${slugify(result.displayName)}`}
                            >
                                <span>
                                    {IconComponent && (
                                        <IconComponent className={styles.searchResultIcon} />
                                    )}
                                    {result.displayName}
                                </span>
                                <FormattedNumber value={result.count} />
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
        /**
         * If the results is defined, that means we received a response from the server.
         * Is loading determines wheter the request is in flight or not.
         * The normalizedResults check indicates wheter we do not have any results to show because
         * the user has selected all of them.
         *
         */
    } else if (results && !isLoading && normalizedResults.length === 0) {
        markup = (
            <FormattedMessage
                id="sb.SbMobileRefineMenu.itemFacetSearch.noResults"
                defaultMessage="No results for {searchString}"
                values={{ searchString }}
            />
        );
    } else {
        return null;
    }

    return (
        <div
            id={id}
            aria-live={ariaLive}
            className={styles.container}
            data-tn={`no-results-${slugify(searchString)}`}
        >
            {markup}
        </div>
    );
}

SbSharedRefineMenuSearchResultsComponent.propTypes = {
    onResultClick: PropTypes.func.isRequired,
    itemFacetSearch: PropTypes.object,
    searchString: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    excludedDisplayNames: PropTypes.array.isRequired,
    iconMap: PropTypes.object,
    id: PropTypes.string.isRequired,
    ariaLive: PropTypes.oneOf(['off', 'polite']),
};

export const SbSharedRefineMenuSearchResults = createFragmentContainer(
    SbSharedRefineMenuSearchResultsComponent,
    {
        itemFacetSearch: graphql`
            fragment SbSharedRefineMenuSearchResults_itemFacetSearch on ItemFacetsQueryConnection {
                edges {
                    node {
                        urlLabel
                        linkReference
                        displayName
                        count
                    }
                }
            }
        `,
    }
);
