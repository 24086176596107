import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { IntlContext, defineMessages } from 'dibs-react-intl';
import { IdConsumer } from 'dibs-uid';
import { updateUriRef } from '../../../actions/filterActions';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';

// components
import { SbSharedRefineMenuTypeaheadControls } from './SbSharedRefineMenuTypeaheadControls';
import { SbSharedRefineMenuSearchInput } from '../SbSharedRefineMenuSearchInput/SbSharedRefineMenuSearchInput';
import { SbSharedRefineMenuSearchResults } from '../SbSharedRefineMenuSearchResults/SbSharedRefineMenuSearchResults';

// styles
import styles from './SbSharedRefineMenuTypeahead.scss';

// constants
const THRESHOLD_MIN_SEARCH_STRING_LENGTH = 3;
const THRESHOLD_FETCH_DATA_DEBOUNCE = 250;

const messages = defineMessages({
    searchBar: {
        id: 'sb.SbSharedRefineMenu.typeahead.searchBar',
        defaultMessage: `Search`,
    },
});

class SbSharedRefineMenuTypeaheadComponent extends Component {
    constructor() {
        super();

        this.state = {
            searchString: '',
            hideResults: true,
            isLoading: false,
            ariaLive: 'off',
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleFetchResponse = this.handleFetchResponse.bind(this);
        this.handleResultClick = this.handleResultClick.bind(this);
        this.fetchSearchData = debounce(
            this.fetchSearchData.bind(this),
            THRESHOLD_FETCH_DATA_DEBOUNCE
        );
    }

    handleInput(e) {
        const { value: searchString } = e.target;

        if (searchString !== '' && searchString.length >= THRESHOLD_MIN_SEARCH_STRING_LENGTH) {
            this.fetchSearchData(searchString);
            this.setState({
                isLoading: true,
                searchString,
            });
        } else {
            this.setState({
                hideResults: true,
                searchString,
            });
        }
    }

    handleResultClick(filterValue, e) {
        this.props.dispatch(
            updateUriRef({
                filterName: this.props.filterName,
                filterValue,
                isFromTypeahead: true,
                event: e,
            })
        );
        this.props.onResultClick();
        /**
         * We intentionally clear the input box so that the user doesn't have to manually delete
         * any text to complete another search. Clearing the results list will also make it more
         * evident to the user that a new search can be entered.
         */
        this.setState({
            searchString: '',
        });
    }

    handleFetchResponse() {
        this.setState({
            isLoading: false,
            hideResults: this.state.searchString === '',
        });
    }

    fetchSearchData(value) {
        const { generatedUriRef } = this.props;
        this.props.relay.refetch(
            {
                uriRef: generatedUriRef,
                hasQuery: true,
                searchString: value,
                facetName: this.props.filterName,
                userCountryCode: getUserSessionCountryCode(),
            },
            null,
            this.handleFetchResponse
        );
    }

    render() {
        const {
            handleToggling,
            viewer,
            filterName,
            excludedDisplayNames,
            iconMap,
            placeholder,
            hideControls,
        } = this.props;
        const { searchString, isLoading, hideResults } = this.state;
        const searchCopy = placeholder || this.context.formatMessage(messages.searchBar);

        return (
            <IdConsumer>
                {htmlId => (
                    <div className={styles.container}>
                        <SbSharedRefineMenuSearchInput
                            ariaControls={htmlId}
                            className={styles.searchInput}
                            onSubmit={e => e.preventDefault()}
                            placeholder={searchCopy}
                            onInput={this.handleInput}
                            value={searchString}
                            filterName={filterName}
                            onBlur={() => this.setState({ ariaLive: 'off' })}
                            onFocus={() => this.setState({ ariaLive: 'polite' })}
                        />
                        <SbSharedRefineMenuSearchResults
                            id={htmlId}
                            ariaLive={this.state.ariaLive}
                            itemFacetSearch={hideResults ? null : viewer.itemFacetSearch}
                            searchString={searchString}
                            isLoading={isLoading}
                            filterName={filterName}
                            excludedDisplayNames={excludedDisplayNames}
                            onResultClick={this.handleResultClick}
                            iconMap={iconMap}
                        />
                        {!hideControls && (
                            <SbSharedRefineMenuTypeaheadControls
                                showSearch={false}
                                onToggleClick={handleToggling}
                                filterName={filterName}
                            />
                        )}
                    </div>
                )}
            </IdConsumer>
        );
    }
}

function mapStateToProps({ filters }) {
    const { generatedUriRef } = filters;
    return {
        generatedUriRef,
    };
}

SbSharedRefineMenuTypeaheadComponent.contextType = IntlContext;

SbSharedRefineMenuTypeaheadComponent.propTypes = {
    handleToggling: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    onResultClick: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    filterName: PropTypes.string.isRequired,
    excludedDisplayNames: PropTypes.array.isRequired,
    RefineMenuTypeaheadWrapper: PropTypes.element,
    iconMap: PropTypes.object,
    generatedUriRef: PropTypes.string,
    placeholder: PropTypes.string,
    hideControls: PropTypes.bool,
};

export const SbSharedRefineMenuTypeahead = createRefetchContainer(
    connect(mapStateToProps)(SbSharedRefineMenuTypeaheadComponent),
    {
        viewer: graphql`
            fragment SbSharedRefineMenuTypeahead_viewer on Viewer
            @argumentDefinitions(
                uriRef: { type: "String" }
                searchString: { type: "String" }
                facetName: { type: "String" }
                hasQuery: { type: "Boolean", defaultValue: false }
                userCountryCode: { type: "String", defaultValue: "" }
            ) {
                itemFacetSearch(
                    searchString: $searchString
                    uriRef: $uriRef
                    facetName: $facetName
                    countryCode: $userCountryCode
                ) @include(if: $hasQuery) {
                    ...SbSharedRefineMenuSearchResults_itemFacetSearch
                }
            }
        `,
    },
    graphql`
        query SbSharedRefineMenuTypeaheadQuery(
            $searchString: String
            $hasQuery: Boolean!
            $facetName: String
            $uriRef: String
            $userCountryCode: String = ""
        ) {
            viewer {
                ...SbSharedRefineMenuTypeahead_viewer
                    @arguments(
                        uriRef: $uriRef
                        searchString: $searchString
                        hasQuery: $hasQuery
                        facetName: $facetName
                        userCountryCode: $userCountryCode
                    )
            }
        }
    `
);
